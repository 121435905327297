import router from '@/router';
import store from '@/store';

export function ApiError(error) {
  this.status = error.response?.status;
  this.errors = error.response?.data?.errors;
  this.detail = '';
  const messages = [];

  if (!error.response?.data) {
    this.detail = error;
    console.error(error);
    return;
  }
  if (error.response.data?.errors) {
    Object.entries(error.response.data?.errors).forEach((el) => {
      messages.push(`${el[0]}: ${el[1].join(' ')}`);
    });
  } else {
    messages.push(error.response.data.message);
  }
  this.detail = messages.join('; ');
  console.error(this.detail);

  /***
   * @param {object} formErrors fields error dict
   */
  this.throwFormErrors = (formErrors) => {
    const errLst = (error.response.data?.errors && Object.keys(error.response.data.errors)) || [];
    if (errLst.length) {
      errLst.forEach((err) => formErrors[err] = error.response.data.errors[err].join(' '));
    }
  };
}

export async function _request(func, ...args) {
  let response = null;
  let error = null;
  try {
    response = await func(...args);
  } catch (e) {
    error = new ApiError(e);
    switch (error.status) {
      case 401:
      case 419:
        store.commit('setUser', {});
        await router.push({name: 'login'});
        break;
      case 403:
        await router.push({name: 'Forbidden'});
        break;
      case 404:
        await router.push({name: 'NotFound'});
        break;
      case 500:
        await router.push({name: 'ServerError'});
        break;
    }
  }
  return [response, error];
}
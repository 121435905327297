import moment from 'moment';
import {ENTITY_STATUSES} from '@/utils/constants';
import {isArray} from 'lodash';

/**
 * @param {Event} event
 * @param {function} emit
 */
export const onScroll = (event, emit) => {
  const {clientHeight, scrollHeight, scrollTop} = event.target;
  if ((scrollHeight - clientHeight - scrollTop) <= 0.5 ) {
    emit('on-scroll');
  }
};

/**
 * @param {Date} date
 * @param {boolean} isHours
 * @returns {string} formatted string for data from api
 */
export const formatDate = (date, isHours = true) => {
  if (isHours) {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }
  return moment(date).format('DD.MM.YYYY');
};

export const addDaysToDate = (date, days) => moment(date).add(days, 'days').format('DD.MM.YYYY');

export function setQueryString() {
  const filtersQuery = {};
  const joiningObjectValue = (value) => {
    const localValue = isArray(value) ? value : [value];
    return localValue.map((item) => {
      if (!Array.isArray(item) && typeof item === 'object') {
        return Object.values(item).join('|');
      }
      return item;
    });
  };

  Object.entries(this.filters).forEach(([key, value]) => {
    if (value && (!isNaN(value) || value?.length)) {
      filtersQuery[key] = joiningObjectValue(value);
    }
  });

  this.$router.push({name: this.$route.name, query: filtersQuery});
  this.fetchData();
}

/**
 * Getting filters from query URLs to populate the filter block of the page upon page load
 * @returns {Promise<void>}
 */
export async function setFiltersOnLoad() {
  const query = this.$route.query;
  const convertStringsToPeriods = (strings) => {
    return strings.map((str) => {
      const [started_at, ended_at] = str.split('|');
      return {started_at, ended_at};
    });
  };

  Object.keys(this.filters).forEach((key) => {
    if (key === 'periods' && query.periods) {
      if (Array.isArray(query.periods)) {
        this.filters.periods = convertStringsToPeriods(query.periods);
      } else {
        this.filters.periods = [convertStringsToPeriods([query.periods])[0]];
      }
    } else if (!Array.isArray(this.filters[key])) {
      this.filters[key] = query[key] || '';
    } else {
      if (query[key]) {
        this.filters[key] = Array.isArray(query[key])
          ? query[key].map((item) => +item)
          : [+query[key]];
      }
    }
  });
  await this.fetchData();
  await this.getMissedFilters();
}
/**
* Set ordering by given attr
* @param {string} sortBy
*/
export function setOrdering(sortBy) {
  this.filters['sort_by'] = sortBy;
  this.filters['order'] = !this.filters['order'] ? 'asc' : this.filters['order'] === 'asc' ? 'desc' : 'asc';
  this.setQueryString();
}
/**
* Remove ordering from filter/query
*/
export function removeOrdering() {
  delete this.filters['sort_by'];
  delete this.filters['order'];
  this.setQueryString();
}
/**
* Number format
*/
export function numberWithSpaces(x) {
  if (x) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }
  return x;
}

/**
* @param {Object | null} user
* @param {boolean} initials optional param for converting second name: John Doe => John D.
* @returns {string}
*/
export const getFullName = (user, initials = false) => {
  if (!user) {
    return '';
  }
  return `${user.first_name || ''} ${initials ? `${user.last_name[0]}.` || '' : user.last_name || ''}`;
};

export function getOrderStatusClass(status) {
  switch (status) {
    case 1:
      return 'draft';
    case 2:
      return 'signed';
    case 3:
      return 'deleted';
    case 4:
      return 'distributed';
    case 5:
      return 'partlyDistributed';
    default:
      return '';
  }
}

export function getSupplyStatusClass(status) {
  switch (status) {
    case 1:
      return 'active';
    case 2:
      return 'deleted';
    default:
      return '';
  }
}

export function getInvoiceStatusClass(status) {
  switch (status) {
    case 1:
      return 'draft';
    case 2:
      return 'signedSupplier';
    case 3:
      return 'signedReceiver';
    case 4:
      return 'signedAll';
    case 5:
      return 'canceled';
    default:
      return '';
  }
}

export const clearFilters = (filters) => {
  let local_filters = {...filters};
  Object.keys(local_filters).forEach((key) => {
    if (!local_filters[key]) {
      delete local_filters[key];
    }
  });
  return local_filters;
};

export const serverDateFormat = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }
  return '';
};

export function toSnakeCase(str) {
  return str.replace(/([A-Z])/g, '$1').toLowerCase();
}

export function keysToSnakeCase(obj) {
  if (obj instanceof Array) {
    return obj.map((element) => this.keysToSnakeCase(element));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[toSnakeCase(key)] = this.keysToSnakeCase(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

export function isElementInViewport(element) {
  if (!element) {
    return;
  }
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

/**
 * Updates favicon depends on app section
 *
 * @param {Object} path - favicon object
 * @param {string} path.light - path to light favicon
 * @param {string} path.dark - path to dark favicon
 */
export const updateFavicon = (path) => {
  document.querySelector('link[rel="icon"][media*="prefers-color-scheme: light"]')
    .href = `${process.env.BASE_URL}${path.light}`;
  document.querySelector('link[rel="icon"][media*="prefers-color-scheme: dark"]')
    .href = `${process.env.BASE_URL}${path.dark}`;
};

export function setupPagination(route, pagination) {
  pagination.page = route.query.page || 1;
  pagination.limit = route.query.limit || 20;
}

export const getEntityState = (entity, statusId) => {
  return ENTITY_STATUSES[entity].find(({status_id}) => status_id === statusId);
};

export const getEntityByValue = (entity, paramValue) => {
  return ENTITY_STATUSES[entity].find((obj) => {
    if (obj.status !== paramValue) {
      return;
    }
    return obj;
  });
};

export const getDefaultError = (error, timeout = 4000, iconClass = 'i-alert') => {
  const type = 'reject';
  const splittedText = error.detail.split(': ');
  const text = splittedText.length > 1 ? splittedText[1] : splittedText[0];

  return {
    text, type, iconClass, timeout
  };
};

export const goBack = () => {
  window.history.back();
};

export const downloadJSON = (data, fileName) => {
  const jsonString = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonString], {type: 'application/json'});
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.json`;

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

/**
 * Formats a phone number to the desired format
 * @param {string | number} phone
 * @returns {string}
 */
export const formatPhoneNumber = (phone) => {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(380|0)?(\d{2})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return ['+380', ' ', match[2], ' ', match[3], ' ', match[4], ' ', match[5]].join('');
  }

  return phone;
};
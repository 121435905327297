export default {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      el.contains(event.target);
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
  name: 'click-outside'
};

import {createRouter, createWebHistory} from 'vue-router';
import api from '@/api';
import store from '@/store';
import {TOP_LEVEL_MODULE_LIST} from '@/utils/roles';

const routes = [
  {
    path: '/ui-kit',
    name: 'ui-kit',
    component: () => import('@/views/UiKit'),
    meta: {title: 'UiKit'}
  },
  // Home route
  {
    path: '/',
    name: 'home',
  },
  // Login routes
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView'),
    meta: {title: 'DOT - Логін', isLogin: true}
  },
  {
    path: '/reset-password/:token?',
    name: 'password-reset',
    component: () => import('@/views/PasswordResetView'),
    meta: {title: 'DOT - Відновлення паролю', isLogin: true}
  },
  // Order routes
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Orders'),
    meta: {title: 'Управління потребами тилового забезпечення ЗСУ - Заявки | DOT-Chain', isOrders: true},
  },
  {
    path: '/orders/create/:orderTypeId',
    name: 'order.create',
    component: () => import('@/views/Orders/Create'),
    meta: {title: 'Управління потребами тилового забезпечення ЗСУ - Створення Заявки | DOT-Chain', isOrders: true}
  },
  {
    path: '/orders/:id/edit',
    name: 'order.edit',
    component: () => import('@/views/Orders/Update'),
    meta: {title: 'Управління потребами тилового забезпечення ЗСУ - Редагування Заявки | DOT-Chain', isOrders: true}
  },
  // Organization routes
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/views/Organizations'),
    meta: {title: 'Бекофіс - Реєстр організацій | DOT-Chain', isOrganizations: true}
  },
  {
    path: '/organizations/create',
    name: 'organizations create',
    component: () => import('@/views/Organizations/Create'),
    meta: {title: 'Бекофіс - Створення організації | DOT-Chain', isOrganizations: true}
  },
  {
    path: '/organizations/:organization_id',
    name: 'organization',
    component: () => import('@/views/Organizations/Update'),
    meta: {title: 'Бекофіс - Деталі організації | DOT-Chain', isOrganizations: true}
  },
  {
    path: '/organizations/:organization_id/update',
    name: 'organization update',
    component: () => import('@/views/Organizations/Update'),
    meta: {title: 'Бекофіс - Оновлення організації | DOT-Chain', isOrganizations: true}
  },

  // Agreement routes
  {
    path: '/agreements',
    name: 'agreements',
    component: () => import('@/views/Agreements'),
    meta: {title: 'DOT - Реєстр договорів', isAgreements: true}
  },
  {
    path: '/agreements/сreate',
    name: 'agreement create',
    component: () => import('@/views/Agreements/Update'),
    meta: {title: 'DOT - Cтворення договору', isAgreements: true}
  },
  {
    path: '/agreements/:agreement_id/update',
    name: 'agreement update',
    component: () => import('@/views/Agreements/Update'),
    meta: {title: 'DOT - Оновлення договору', isAgreements: true}
  },

  // OrganizationUnit routes
  {
    path: '/organization-units',
    name: 'organization-units',
    component: () => import('@/views/OrganizationUnits'),
    meta: {title: 'Бекофіс - Реєстр підрозділів | DOT-Chain', isOrganizationUnits: true}
  },
  {
    path: '/organization-units/create',
    name: 'organization-units create',
    component: () => import('@/views/OrganizationUnits/Create'),
    meta: {title: 'Бекофіс - Створення підрозділу | DOT-Chain', isOrganizationUnits: true}
  },
  {
    path: '/organization-units/:organization_unit_id',
    name: 'organization unit',
    component: () => import('@/views/OrganizationUnits/Update'),
    meta: {title: 'Бекофіс - Деталі підрозділу | DOT-Chain', isOrganizationUnits: true}
  },
  {
    path: '/organization-units/:organization_unit_id/update',
    name: 'organization unit update',
    component: () => import('@/views/OrganizationUnits/Update'),
    meta: {title: 'Бекофіс - Оновлення підроздиілу | DOT-Chain', isOrganizationUnits: true}
  },
  // User routes
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users'),
    meta: {title: 'Бекофіс - Реєстр користувачів | DOT-Chain', isUsers: true}
  },
  {
    path: '/users/create',
    name: 'users create',
    component: () => import('@/views/Users/Create'),
    meta: {title: 'Бекофіс - Створення користувача | DOT-Chain', isUsers: true}
  },
  {
    path: '/users/:user_id',
    name: 'user',
    component: () => import('@/views/Users/Update'),
    meta: {title: 'Бекофіс - Деталі користувача | DOT-Chain', isUsers: true}
  },
  {
    path: '/users/:user_id/update',
    name: 'user update',
    component: () => import('@/views/Users/Update'),
    meta: {title: 'Бекофіс - Оновлення користувача | DOT-Chain', isUsers: true}
  },
  // Suppliers routes
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('@/views/Suppliers'),
    meta: {title: 'DOT - Реєстр контрагентів', isSuppliers: true}
  },
  {
    path: '/suppliers/create',
    name: 'suppliers create',
    component: () => import('@/views/Suppliers/Create'),
    meta: {title: 'DOT - Створення контрагента', isSuppliers: true}
  },
  {
    path: '/suppliers/:supplier_id',
    name: 'supplier unit',
    component: () => import('@/views/Suppliers/Update'),
    meta: {title: 'DOT - Деталі контрагента', isSuppliers: true}
  },
  {
    path: '/suppliers/:supplier_id/update',
    name: 'supplier update',
    component: () => import('@/views/Suppliers/Update'),
    meta: {title: 'DOT - Оновлення контрагента', isSuppliers: true}
  },
  // Supplies routes
  {
    path: '/supplies',
    name: 'supplies',
    component: () => import('@/views/Supplies'),
    meta: {title: 'DOT - Реєстр Замовлень', isSupplies: true}
  },
  {
    path: '/supplies/create',
    name: 'supplies.create',
    component: () => import('@/views/Supplies/Create'),
    meta: {title: 'DOT - Створення замовлень', isSupplies: true}
  },
  {
    path: '/supplies/:id/create',
    name: 'amendments.create',
    component: () => import('@/views/SupplyAmendments/Update'),
    meta: {title: 'DOT - Створення запиту на коригування', isSupplies: true}
  },
  {
    path: '/supplies/:id/edit',
    name: 'supply.edit',
    component: () => import('@/views/Supplies/Update'),
    meta: {title: 'DOT - Оновлення замовлення', isSupplies: true}
  },
  // Invoices routes
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/Invoices'),
    meta: {title: 'DOT - Реєстр ВН', isInvoices: true}
  },
  {
    path: '/invoices/:invoice_id/edit',
    name: 'invoices update',
    component: () => import('@/views/Invoices/Update'),
    meta: {title: 'DOT - Оновлення ВН', isInvoices: true}
  },
  {
    path: '/supply-amendments',
    name: 'supplyAmendments',
    component: () => import('@/views/SupplyAmendments'),
    meta: {title: 'DOT - Реєстр запитів на коригування', isSuppliesAmendments: true}
  },
  {
    path: '/supplies/:id/amendments/:amendment_id/edit',
    name: 'supplyAmendments update',
    component: () => import('@/views/SupplyAmendments/Update'),
    meta: {title: 'DOT - Зміна запиту на коригування', isSuppliesAmendments: true}
  },
  {
    path: '/server-error',
    name: 'ServerError',
    component: () => import('@/views/Errors/ServerError.vue'),
    meta: {title: 'Помилка серверу'}
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('@/views/Errors/Forbidden.vue'),
    meta: {title: 'Недостатньо прав'}
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/Errors/NotFound.vue'),
    meta: {title: 'Сторінка не знайдена'}
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile/index.vue'),
    meta: {title: 'DOT - Профіль користувача'}
  },
  {
    path: '/profile/token/create',
    name: 'tokenCreate',
    component: () => import('@/views/Profile/Token/Create/index.vue'),
    meta: {title: 'DOT - Створення токену'}
  },
  {
    path: '/receipts',
    name: 'receipts',
    component: () => import('@/views/Receipts'),
    meta: {title: 'DOT - Реєстр актів', isReceipts: true}
  },
  {
    path: '/receipts/create',
    name: 'receipts create',
    component: () => import('@/views/Receipts/Create'),
    meta: {title: 'DOT - Створення акту', isReceipts: true}
  },
  {
    path: '/receipts/:receipt_id/update',
    name: 'receipt update',
    component: () => import('@/views/Receipts/Update'),
    meta: {title: 'DOT - Оновлення акту', isReceipts: true}
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
});

let appVerFetched = false;
let cachedAppVerResponse = null;

router.beforeEach(async (to, from, next) => {
  if (store.state.user.id && to.meta.isLogin) {
    next({name: 'home'});
    return;
  }
  if (!to.meta.isLogin) {
    const [response] = await api.bulkGrants.list({module: TOP_LEVEL_MODULE_LIST});
    if (response) {
      store.commit('setPermissions', response.data.modules);
      store.commit('setRoles', response.data.roles);
    }

    if (to.name === 'home' && store.state.user.id) {
      if (!appVerFetched) {
        const [appVerResponse] = await api.version.getVersion();
        if (appVerResponse) {
          cachedAppVerResponse = appVerResponse;
          console.log(`Backend: ${appVerResponse.data.app_version} \nFrontend: ${process.env.VUE_APP_VERSION}`);
          appVerFetched = true;
        }
      } else {
        console.log(`Backend: ${cachedAppVerResponse.data.app_version} \nFrontend: ${process.env.VUE_APP_VERSION}`);
      }

      store.dispatch('setActiveSectionAndRedirect');
      next();
      return;
    }
  }
  next();
});

export default router;

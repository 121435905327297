<template>
  <main :class="{'is-login-page': isLandingRoute}">
    <dot-header v-if="!isLandingRoute" />
    <div class="wrapper">
      <aside class="wrapper__sidebar" :class="{collapsed: isMenuCollapsed}" v-if="!isLandingRoute && !isMobile">
        <dot-menu />
      </aside>
      <router-view />
    </div>
  </main>
</template>

<script>
import DotHeader from '@/components/header/Header.vue';
import DotMenu from '@/components/DotMenu.vue';
import mobileWatcher from '@/mixins/MobileWatcherMixin';
import {mapGetters} from 'vuex';

export default {
  name: 'App',
  mixins: [mobileWatcher],
  components: {
    DotHeader,
    DotMenu
  },
  watch: {
    $route: {
      immediate: true,
      handler(to,) {
        document.title = to.meta.title;
      }
    },
  },
  mounted() {
    window.addEventListener('load', () => {
      this.$store.commit('setReady', true);
    });
  },
  computed: {
    ...mapGetters(['isMenuCollapsed']),
    isLandingRoute() {
      return ['login', 'password-reset'].includes(this.$route.name);
    },
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>

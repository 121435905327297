import {mapMutations} from 'vuex';

const TABLET_LESS_MORE_BP = 768;

export default {
  data() {
    return {
      windowWidth: null,
    };
  },

  props: {
    tablet: {
      type: Number,
      default: TABLET_LESS_MORE_BP,
    },
  },

  created() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  methods: {
    ...mapMutations(['setMobile']),
    onResize() {
      this.windowWidth = window.innerWidth;
      this.$store.commit('setMobile', this.windowWidth < this.tablet);
    },
  },

  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
    }
  }
};

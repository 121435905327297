<template>
  <ul
    class="dot-menu"
    :class="{ 'collapsed': isMenuCollapsed }"
    v-if="$store.state.roles.length && hasVisibleItems"
  >
    <li
      class="dot-menu__item"
      v-if="hasPermission('orders', VIEW_GRANTS) && isCMS"
    >
      <router-link
        class="active-link"
        :class="{active: $route.meta.isOrders}"
        :to="{name: 'orders', query: {page: 1, limit: 20}}"
      >
        <i class="i-order"/>
        <span v-if="!isMenuCollapsed">Заявки</span>
      </router-link>
    </li>
    <li
      class="dot-menu__item"
      v-if="hasPermission('supplies', VIEW_GRANTS) && isCMS"
    >
      <router-link
        class="active-link"
        :class="{active: $route.meta.isSupplies}"
        :to="{name: 'supplies', query: {page: 1, limit: 20}}"
      >
        <i class="i-alert-success"/>
        <span v-if="!isMenuCollapsed">Замовлення</span>
      </router-link>
      <button
        v-if="!isMenuCollapsed && hasPermission('supply-amendments', VIEW_GRANTS)"
        class="sub-item-switcher"
        type="button"
        @click.stop="toggleSubMenu('supplies')"
      >
        <i :class="getSubMenuState('supplies') ? 'i-chevron-up' : 'i-chevron-down'" />
      </button>
      <button
        v-if="isMenuCollapsed"
        class="sub-item-switcher i-chevron-right mr-10"
        type="button"
        @mouseover="showSubMenu('supplies', true)"
        @mouseleave="delayedHideSubMenu('supplies')"
      >
        <i :class="getSubMenuState('supplies') ? 'i-chevron-up' : 'i-chevron-down'" />
      </button>
      <div
        class="popup-menu"
        v-if="isMenuCollapsed && getSubMenuState('supplies')"
        @mouseover="cancelHideSubMenu"
        @mouseleave="delayedHideSubMenu('supplies')">
        <router-link
          class="popup-menu__item"
          :class="{active: $route.meta.isSuppliesAmendments}"
          :to="{name: 'supplyAmendments', query: {page: 1, limit: 20}}"
        >
          <i class="i-edit-pencil"/>
          <span>Коригування</span>
        </router-link>
      </div>
    </li>
    <li class="dot-menu__item dot-menu__sub-item" v-show="isCMS && getSubMenuState('supplies') && hasPermission('supply-amendments', VIEW_GRANTS)">
      <router-link
        class="active-link"
        :class="{active: $route.meta.isSuppliesAmendments}"
        :to="{name: 'supplyAmendments', query: {page: 1, limit: 20}}"
      >
        <i class="i-rubber-pencil"/>
        <span v-if="!isMenuCollapsed">Коригування</span>
      </router-link>
    </li>
    <li
      class="dot-menu__item"
      v-if="hasPermission('invoices', VIEW_GRANTS) && isCMS"
      @click="$router.push({name: 'invoices', query: {page: 1, limit: 20}})"
    >
      <router-link
        class="active-link"
        :class="{active: $route.meta.isInvoices}"
        :to="{name: 'invoices'}"
      >
        <i class="i-invoice-menu"/>
        <span v-if="!isMenuCollapsed">ВН</span>
      </router-link>
    </li>
    <li
      class="dot-menu__item"
      v-if="hasPermission('receipts', VIEW_GRANTS) && isCMS"
      @click="$router.push({name: 'receipts', query: {page: 1, limit: 20}})"
    >
      <router-link
        class="active-link"
        :class="{active: $route.meta.isReceipts}"
        :to="{name: 'receipts'}"
      >
        <i class="i-tablet"/>
        <span v-if="!isMenuCollapsed">Акти</span>
      </router-link>
    </li>
    <li
      class="dot-menu__item"
      v-if="hasPermission('users', VIEW_GRANTS) && isBackOffice"
      @click="$router.push({name: 'users', query: {page: 1, limit: 20}})"
    >
      <router-link
        class="active-link"
        :class="{active: $route.meta.isUsers}"
        :to="{name: 'users'}"
      >
        <i class="i-users"/>
        <span v-if="!isMenuCollapsed">Користувачі</span>
      </router-link>
    </li>
    <li
      class="dot-menu__item"
      v-if="hasPermission('organizations', VIEW_GRANTS) && isBackOffice"
      @click="$router.push({name: 'organizations', query: {page: 1, limit: 20}})"
    >
      <router-link
        class="active-link"
        :class="{active: $route.meta.isOrganizations}"
        :to="{name: 'organizations'}"
      >
        <i class="i-organization"/>
        <span v-if="!isMenuCollapsed">Організації</span>
      </router-link>
    </li>
    <li
      class="dot-menu__item"
      v-if="hasPermission('organization-units', VIEW_GRANTS) && isBackOffice"
      @click="$router.push({name: 'organization-units', query: {page: 1, limit: 20}})"
    >
      <router-link
        class="active-link"
        :class="{active: $route.meta.isOrganizationUnits}"
        :to="{name: 'organization-units'}"
      >
        <i class="i-unit"/>
        <span v-if="!isMenuCollapsed">Підрозділи</span>
      </router-link>
    </li>
    <li
      class="dot-menu__item"
      v-if="hasPermission('suppliers', VIEW_GRANTS) && isBackOffice"
    >
      <router-link
        class="active-link"
        :class="{active: $route.meta.isSuppliers}"
        :to="{name: 'suppliers', query: {page: 1, limit: 20}}"
      >
        <i class="i-link"/>
        <span v-if="!isMenuCollapsed">Контрагенти</span>
      </router-link>
      <button
        v-if="!isMenuCollapsed && hasPermission('agreements', VIEW_GRANTS)"
        class="sub-item-switcher"
        type="button"
        @click.stop="toggleSubMenu('suppliers')"
      >
        <i :class="getSubMenuState('suppliers') ? 'i-chevron-up' : 'i-chevron-down'" />
      </button>
      <button
        v-if="isMenuCollapsed"
        class="sub-item-switcher i-chevron-right mr-10"
        type="button"
        @mouseover="showSubMenu('suppliers', true)"
        @mouseleave="delayedHideSubMenu('suppliers')"
      >
        <i :class="getSubMenuState('suppliers') ? 'i-chevron-up' : 'i-chevron-down'" />
      </button>
      <div
        class="popup-menu"
        v-if="isMenuCollapsed && getSubMenuState('suppliers')"
        @mouseover="cancelHideSubMenu"
        @mouseleave="delayedHideSubMenu('suppliers')">
        <router-link
          class="popup-menu__item"
          :class="{active: $route.meta.isAgreements}"
          :to="{name: 'agreements', query: {page: 1, limit: 20}}"
        >
          <i class="i-file"/>
          <span>Договори</span>
        </router-link>
      </div>
    </li>
    <li class="dot-menu__item dot-menu__sub-item" v-show="isBackOffice && getSubMenuState('suppliers') && hasPermission('agreements', VIEW_GRANTS)">
      <router-link
        class="active-link"
        :class="{active: $route.meta.isAgreements}"
        :to="{name: 'agreements', query: {page: 1, limit: 20}}"
      >
        <i class="i-file"/>
        <span v-if="!isMenuCollapsed">Договори</span>
      </router-link>
    </li>
  </ul>
  <button
    v-if="!isMobile"
    type="button"
    :class="['expand-btn', {close: isMenuCollapsed}]"
    @click="toggleMenu"
  >
    <i class="i-expand" />
  </button>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {APP_SECTION, VIEW_GRANTS} from '@/utils/roles';

export default {
  name: 'DotMenu',
  methods: {
    ...mapMutations(['toggleMenu', 'toggleSubMenu', 'setSubMenuState']),
    showSubMenu(item, value) {
      this.setSubMenuState({item, value});
      clearTimeout(this.hideTimeout);
    },
    delayedHideSubMenu(item) {
      this.hideTimeout = setTimeout(() => {
        this.setSubMenuState({item, value: false});
      }, 300);
    },
    cancelHideSubMenu() {
      clearTimeout(this.hideTimeout);
    }
  },
  computed: {
    ...mapGetters(['hasPermission', 'isCMS', 'isBackOffice', 'isMenuCollapsed', 'getSubMenuState']),
    VIEW_GRANTS() {
      return VIEW_GRANTS;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    hasVisibleItems() {
      if (this.isCMS) {
        return APP_SECTION.cms.some((module) => this.hasPermission(module, VIEW_GRANTS));
      }
      return APP_SECTION.backoffice.some((module) => this.hasPermission(module, VIEW_GRANTS));
    }
  },
};
</script>
<style lang="scss" scoped>
.dot-menu {
  display: flex;
  width: 100%;
  flex-flow: column;
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  padding: 12px 0 12px 0;
  overflow: hidden;
  transition: width 0.3s ease;

  &.collapsed {
    .dot-menu__item .sub-item-switcher {
      margin: 0;
      padding: 0;
    }
    width: 60px;

    .active-link {
      span {
        display: none;
      }
    }
  }

  @include tablet-and-more {
    width: 200px;
    border: 2px solid $grey-e5;
    border-left: 0;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    i {
      width: 24px;
      height: 24px;
    }
    &:hover {
      background-color: $grey-ed;
    }
  }

  &__sub-item {
    border-top: 1px solid $grey-c6;
    border-bottom: 1px solid $grey-c6;
  }

  .active-link {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 30px;
    padding: 5px 0 5px 15px;
    font-size: 13px;
    background-color: transparent;
    transition: background-color .1s;

    @include tablet-and-more {
      gap: 6px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      display: block;
      width: 6px;
      height: 30px;
      transform: matrix(1, 0, 0, -1, 0, 0);
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      background: transparent;
    }

    &.active {
      &:before {
        background: radial-gradient(51.67% 124.53% at 34.24% -5.59%, #737373 0%, #000000 100%);
      }
    }

    .i-alert-success {
      width: 24px;
      height: 24px;
    }

    .i-rubber-pencil {
      width: 21px;
      margin-left: 3px;
    }
  }
}

.expand-btn {
  padding: 15px;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &.close {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: $grey-ed;
  }
}

.popup-menu {
  position: absolute;
  left: 70px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 4px;
}

.popup-menu__item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px;
  font-size: 13px;
  color: black;

  &:hover {
    background-color: $grey-ed;
    border-radius: 4px;
  }
}

.sub-item-switcher {
  flex: 0 0 50px;
  height: 100%;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 4px;

  @include tablet-and-more {
    flex: 0 1 40px;
  }

  &:hover {
    background-color: darken($grey-ed, 10%);
  }

  i {
    width: 20px;
    height: 20px;
  }
}
</style>
<template>
  <div class="user-card">
    <div
      class="user-card__info"
      :class="{'user-card__info--reverse': reverse}"
    >
      <div class="user-card__info-inner">
        <div class="user-card__subtitle">{{ user.subtitle }}</div>
        <div>{{ user.title }}</div>
      </div>
      <div class="user-card__initials">{{ user.initials }}</div>
    </div>

    <slot/>
  </div>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    reverse: {type: Boolean, default: false},
    user: {type: Object, default: () => ({})},
  },
};
</script>
/**
 * Replaces variables in target string taking values from given match object
 * @param {string} targetString
 * @param {Object} matchObject
 * @param {string} prefix
 */
const substring = (targetString, matchObject, prefix = ':') => {
  for (let [key, value] of Object.entries(matchObject)) {
    targetString = targetString.replace(`${prefix}${key}`, value);
  }
  return targetString;
};

/**
 * Replace :<variable> with fmt[<var>]
 * @param {Object} matchObject
 * @returns {string}
 */
String.prototype.format = function (matchObject) {
  return substring(this, matchObject);
};

<template>
  <header class="header" :class="{'is-mobile': isMobile}">
    <nav class="header__container">
      <div class="header__left">
        <router-link :to="{name: 'home'}" class="header__logo">
          <i :class="isMobile ? 'i-logo-mob' : 'i-main-logo'"/>
        </router-link>
        <div class="header-dropdown" v-click-outside="() => isDropdownOpen = false">
          <button class="header-dropdown__btn btn-transparent font-m-400" @click="isDropdownOpen = !isDropdownOpen">
            <i class="i-24" :class="{'i-cms-logo': isCMS, 'i-backoffice': isBackOffice}"/>
            <span class="header-dropdown__btn--title">{{ isCMS ? 'CMS' : 'Backoffice' }}</span>
            <i class="i-16" :class="{'i-chevron-down': !isDropdownOpen, 'i-chevron-up': isDropdownOpen}"/>
          </button>
          <div class="header-dropdown__content font-sm-400" v-show="isDropdownOpen">
            <button v-if="hasCmsPermission" @click="setActiveSection('cms')">
              <i class="i-cms-logo"/> CMS
            </button>
            <button v-if="hasBackofficePermission" @click="setActiveSection('backoffice')">
              <i class="i-backoffice"/> Backoffice
            </button>
          </div>
        </div>
      </div>
      <div class="header__right">
        <template v-if="!isMobile">
          <div class="help-wrapper">
            <a class="help-wrapper__link" href="https://dotua.atlassian.net/servicedesk" target="_blank">Допомога</a>
          </div>
          <user-card
            :user="userCard"
            @click="isUserCardOpen = !isUserCardOpen"
            v-click-outside="() => isUserCardOpen = false"
          >
            <template #default>
              <ul class="user-card__dropdown" v-if="!isMobile && isUserCardOpen">
                <li class="user-card__dropdown-link" @click="profile">
                  <i class="i-profile"/>
                  Профіль
                </li>
                <li class="user-card__dropdown-link"  @click="logOut">
                  <i class="i-exit"/>
                  Вихід
                </li>
              </ul>
            </template>
          </user-card>
        </template>
        <template v-else>
          <div class="mobile-menu">
            <Slide right noOverlay closeOnNavigation>
              <section class="mobile-menu__section">
                <user-card class="user-card--mobile" :user="userCard" />
              </section>
              <section class="mobile-menu__section">
                <dot-menu />
              </section>
              <section class="mobile-menu__section">
                <div class="help-wrapper">
                  <i class="help-wrapper__icon i-support" />
                  <a class="help-wrapper__link" href="https://dotua.atlassian.net/servicedesk" target="_blank">Допомога</a>
                </div>
              </section>
              <section class="mobile-menu__section">
                <button class="mobile-menu__btn" type="button" @click="profile">
                  <span>Профіль</span>
                </button>
                <button class="mobile-menu__btn" type="button" @click="logOut">
                  <i class="mobile-menu__btn-icon i-logout" />
                  <span>Вихід</span>
                </button>
              </section>
            </Slide>
          </div>
        </template>
      </div>
    </nav>
  </header>
</template>

<script>
import {Slide} from 'vue3-burger-menu';
import api from '@/api';
import DotMenu from '@/components/DotMenu.vue';
import UserCard from '@/components/header/UserCard.vue';
import {mapGetters} from 'vuex';
import {APP_SECTION, VIEW_GRANTS} from '@/utils/roles';
import store from '@/store';
import {updateFavicon} from '@/utils/utils';

export default {
  name: 'DotHeader',
  components: {Slide, DotMenu, UserCard},
  data() {
    return {
      isDropdownOpen: false,
      isUserCardOpen: false,
      open: true,
    };
  },
  mounted() {
    store.getters.isBackOffice ?
      updateFavicon({light: 'favicon_backoffice.svg', dark: 'favicon_backoffice.svg'}) :
      updateFavicon({light: 'favicon_cms_dark.png', dark: 'favicon_cms_light.png'});
  },
  computed: {
    ...mapGetters(['hasPermission', 'isCMS', 'isBackOffice']),
    isMobile() {
      return this.$store.state.isMobile;
    },
    hasBackofficePermission() {
      return APP_SECTION.backoffice.some((module) => this.hasPermission(module, VIEW_GRANTS));
    },
    hasCmsPermission() {
      return APP_SECTION.cms.some((module) => this.hasPermission(module, VIEW_GRANTS));
    },
    userCard() {
      return {
        subtitle: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
        title: this.$store.state.user.email,
        initials: `${this.$store.state.user.first_name?.[0] || '' }${this.$store.state.user.last_name?.[0] || ''}`,
      };
    },
  },
  methods: {
    async logOut() {
      const [, error] = await api.auth.logout();
      if (!error) {
        this.$store.commit('setUser', {});
        this.$router.push({name: 'login'});
      }
    },
    profile() {
      this.$router.push({name: 'profile'});
    },
    setActiveSection(section) {
      this.$store.dispatch('setActiveSectionAndRedirect', section);
      store.getters.isBackOffice ?
        updateFavicon({light: 'favicon_backoffice.svg', dark: 'favicon_backoffice.svg'}) :
        updateFavicon({light: 'favicon_cms_dark.png', dark: 'favicon_cms_light.png'});
      this.isDropdownOpen = false;
    }
  }
};
</script>
const gaConfig = {
  production: 'G-JS13YPYYKT',
  development: 'G-QE19DY6464',
};

function getGoogleAnalyticsId() {
  const currentUrl = window.location.href;

  for (const key of ['qa', 'stage', 'localhost', 'frontend']) {
    if (currentUrl.includes(key)) {
      return gaConfig.development;
    }
  }
  return gaConfig.production;
}

const gaId = getGoogleAnalyticsId();

if (gaId) {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', gaId);
  };
}
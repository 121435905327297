import qs from 'qs';
import {_axios, urls} from '@/api/client';
import {_request} from '@/api/helper';
import {isEmpty} from 'lodash';

export class ClientMixin {
  /**
   * Mixin for all endpoints
   * @param {API} client
   */
  constructor(client) {
    this.api = client;
  }
}

class Auth extends ClientMixin {

  /**
   * @param {object} data
   */
  login = async (data) => {
    return this.api.post(urls.auth.login, data);
  }

  logout = async () => {
    return this.api.post(urls.auth.logout);
  }

  csrfToken = async () => {
    return this.api.get(urls.auth.csrf);
  }

  /**
   * @param {object} data
   */
  forgotPassword = async (data) => {
    return this.api.post(urls.auth.forgotPassword, data);
  }

  /**
   * @param {object} data
   */
  resetPassword = async (data) => {
    return this.api.post(urls.auth.resetPassword, data);
  }
}

class Organizations extends ClientMixin {
  /**
   * @param {object} [getParams] optional GET params
   */
  list = async (getParams) => {
    return this.api.get(urls.organizations.list, getParams);
  }
  /**
   * @param {object} data
   */
  create = async (data = {}) => {
    return this.api.post(urls.organizations.list, data);
  }
  /**
  * @param {number} organization_id
  */
  detail = async (organization_id) => {
    return this.api.get(urls.organizations.detail.format({organization_id}));
  };
  /**
  * @param {number} organization_id
  * @param {object} data
  */
  update = async (organization_id, data) => {
    return this.api.put(urls.organizations.detail.format({organization_id}), data);
  };

   search = async (getParams = {}) => {
     return this.api.get(urls.organizations.search, getParams);
   };

  grants = async (organization_id) => {
    return this.api.get(urls.organizations.grants.format({organization_id}));
  }
}

class Agreements extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.agreements.list, getParams);
  };

  /**
  * @param {number} agreement_id
  */
  detail = async (agreement_id) => {
    return this.api.get(urls.agreements.detail.format({agreement_id}));
  };

  /**
  * @param {number} agreement_id
  * @param {object} data
  */
  update = async (agreement_id, data) => {
    return this.api.put(urls.agreements.detail.format({agreement_id}), data);
  };

  /**
  * @param {number} agreement_id
  * @param {object} getParams optional GET params
  */
  agreementPeriodsList = async (agreement_id, getParams = {}) => {
    return this.api.get(urls.agreements.agreementPeriods.format({agreement_id}), getParams);
  };

  /**
  * @param {number} agreement_id
  * @param {object} data
  */
  addAgreementPeriod = async (agreement_id, data) => {
    return this.api.post(urls.agreements.agreementPeriods.format({agreement_id}), data);
  };

  /**
  * @param {number} agreement_id
  * @param {number} period_id
  * @param {object} data
  */
  updateAgreementPeriod = async (agreement_id, period_id, data) => {
    return this.api.put(urls.agreements.agreementPeriod.format({agreement_id, period_id}), data);
  };

  /**
  * @param {number} agreement_id
  * @param {number} period_id
  */
  deleteAgreementPeriod = async (agreement_id, period_id) => {
    return this.api.delete(urls.agreements.agreementPeriod.format({agreement_id, period_id}));
  };

  /**
  * @param {number} agreement_id
  * @param {object} getParams optional GET params
  */
  getProductItems = async (agreement_id, getParams = {}) => {
    return this.api.get(urls.agreements.agreementItems.format({agreement_id}), getParams);
  };

  /**
  * @param {number} agreement_id
  * @param {object} data
  */
  addProductItem = async (agreement_id, data) => {
    return this.api.post(urls.agreements.agreementItems.format({agreement_id}), data);
  };

  /**
  * @param {number} agreement_id
  * @param {number} item_id
  * @param {object} data
  */
  updateProductItem = async (agreement_id, item_id, data) => {
    return this.api.put(urls.agreements.agreementItem.format({agreement_id, item_id}), data);
  };

  /**
  * @param {number} agreement_id
  * @param {number} item_id
  */
  deleteProductItem = async (agreement_id, item_id) => {
    return this.api.delete(urls.agreements.agreementItem.format({agreement_id, item_id}));
  };

  search = async (getParams = {}) => {
    return this.api.get(urls.agreements.search, getParams);
  };

  grants = async (agreement_id) => {
    return this.api.get(urls.agreements.grants.format({agreement_id}));
  }
}

class Users extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.users.list, getParams);
  }
  /**
   * @param {object} data
   */
  create = async (data) => {
    return this.api.post(urls.users.list, data);
  }
  /**
  * @param {number} user_id
  */
  detail = async (user_id) => {
    return this.api.get(urls.users.detail.format({user_id}));
  };
  /**
  * @param {number} user_id
  * @param {object} data
  */
  update = async (user_id, data) => {
    return this.api.put(urls.users.detail.format({user_id}), data);
  };

  roles = async () => {
    return this.api.get(urls.users.roles);
  };

  search = async (getParams = {}) => {
    return this.api.get(urls.users.search, getParams);
  };

  grants = async (user_id) => {
    return this.api.get(urls.users.grants.format({user_id}));
  }
}

class OrganizationUnits extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.organizationUnits.list, getParams);
  }

  search = async (getParams = {}) => {
    return this.api.get(urls.organizationUnits.search, getParams);
  }
  /**
   * @param {object} data
   */
  create = async (data) => {
    return this.api.post(urls.organizationUnits.list, data);
  }
  /**
  * @param {number} organization_unit_id
  */
  detail = async (organization_unit_id) => {
    return this.api.get(urls.organizationUnits.detail.format({organization_unit_id}));
  };
  /**
  * @param {number} organization_unit_id
  * @param {object} data
  */
  update = async (organization_unit_id, data) => {
    return this.api.put(urls.organizationUnits.detail.format({organization_unit_id}), data);
  };

  grants = async (organization_unit_id) => {
    return this.api.get(urls.organizationUnits.grants.format({organization_unit_id}));
  }
}

class TerritorialUnits extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.territorialUnits.list, getParams);
  }
  /**
   * @param {object} data
   */
  create = async (data = {}) => {
    return this.api.post(urls.territorialUnits.list, data);
  }
  search = async (getParams = {}) => {
    return this.api.get(urls.territorialUnits.search, getParams);
  };
}

class Orders extends ClientMixin {

  list = async (getParams = {}) => {
    return this.api.get(urls.orders.list, getParams);
  }

  create = async (data = {}) => {
    return this.api.post(urls.orders.list, data);
  }

  detail = async (order_id) => {
    return this.api.get(urls.orders.detail.format({order_id}));
  }

  search = async (getParams = {}) => {
    return this.api.get(urls.orders.search, getParams);
  };

  grants = async (order_id) => {
    return this.api.get(urls.orders.grants.format({order_id}));
  }

  addFile = async (order_id, data = {}) => {
    return this.api.post(urls.orders.file.format({order_id}), data);
  }

  addResponsibleUser = async (order_id, user_id, data = {}) => {
    return this.api.put(urls.orders.responsibleUser.format({order_id, user_id}), data);
  }

  deleteResponsibleUser = async (order_id, user_id) => {
    return this.api.delete(urls.orders.responsibleUser.format({order_id, user_id}));
  }

  addItem = async (orderID, data = {}) => {
    return this.api.post(`${urls.orders.list}/${orderID}/order-items`, data);
  }

  getItems = async (orderID, getParams = {}) => {
    return this.api.get(`${urls.orders.list}/${orderID}/order-items`, getParams);
  }

  updateItem = async (orderID, itemID, data = {}) => {
    return this.api.put(`${urls.orders.list}/${orderID}/order-items/${itemID}`, data);
  }

  deleteItem = async (orderID, itemID) => {
    return this.api.delete(`${urls.orders.list}/${orderID}/order-items/${itemID}`);
  }

  update = async (order_id, data = {}) => {
    return this.api.put(urls.orders.detail.format({order_id}), data);
  }

  sign = async (orderID, data = {}) => {
    return this.api.post(`${urls.orders.list}/${orderID}/signed`, data);
  }

  getSignData = async (orderID) => {
    return this.api.get(`${urls.orders.list}/${orderID}/signature-data`);
  }

  commentsList = async (orderID) => {
    return this.api.get(`${urls.orders.list}/${orderID}/comments?limit=100`);
  }

  addComment = async (orderID, data) => {
    return this.api.post(`${urls.orders.list}/${orderID}/comments`, data);
  }

  periods = async () => {
    return this.api.get(urls.orders.periods);
  }

  tags = async (order_id) => {
    return this.api.get(urls.orders.tags.format({order_id}));
  }

  createTag = async (order_id, data = {}) => {
    return this.api.post(urls.orders.tags.format({order_id}), data);
  }

  addTag = async (order_id, tag_id, data = {}) => {
    return this.api.put(urls.orders.tagAction.format({order_id, tag_id}), data);
  }

  detachTag = async (order_id, tag_id) => {
    return this.api.delete(urls.orders.tagAction.format({order_id, tag_id}));
  }
}

class Nomenclature extends ClientMixin {
  /**
   * @param {object} date required GET parameter
   */
  list = async (date ) => {
    return this.api.get(urls.nomenclature.json, date);
  }
}

class UserRoles extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.userRoles.list, getParams);
  }
}

class StaticData extends ClientMixin {
  list = async () => {
    return this.api.get(urls.staticData.list);
  }
}

class Suppliers extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.suppliers.list, getParams);
  }
  /**
   * @param {object} data
   */
  create = async (data = {}) => {
    return this.api.post(urls.suppliers.list, data);
  }
  /**
   * @param {object} data
   * @param {number} supplier_id
   */
  createAgreement = async (supplier_id, data) => {
    return this.api.post(urls.suppliers.agreements.format({supplier_id}), data);
  }
  /**
   * @param {number} supplier_id
   */
  detail = async (supplier_id) => {
    return this.api.get(urls.suppliers.detail.format({supplier_id}));
  };
  /**
   * @param {number} supplier_id
   * @param {object} data
   */
  update = async (supplier_id, data) => {
    return this.api.put(urls.suppliers.detail.format({supplier_id}), data);
  };
  /**
   * @param {object} getParams optional GET params
   */
  search = async (getParams = {}) => {
    return this.api.get(urls.suppliers.search, getParams);
  };

  grants = async (supplier_id) => {
    return this.api.get(urls.suppliers.grants.format({supplier_id}));
  }
}

class Tags extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.tags.list, getParams);
  }

  search = async (getParams = {}) => {
    return this.api.get(urls.tags.search, getParams);
  };
}

class Supplies extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.supplies.list, getParams);
  }
  /**
   * @param {object} data
   */
  create = async (data = {}) => {
    return this.api.post(urls.supplies.list, data);
  }
  /**
   * @param {number} supply_id
   */
  detail = async (supply_id) => {
    return this.api.get(urls.supplies.detail.format({supply_id}));
  };
  /**
   * @param {number} supply_id
   * @param {object} getParams optional GET params
   */
  getProductItems = async (supply_id, getParams = {}) => {
    return this.api.get(urls.supplies.supplyItems.format({supply_id}), getParams);
  };
  /**
   * @param {object} getParams
   * @returns {Promise<Array<{response: Promise<import('axios').AxiosResponse<*>>, error: (ApiError|null)}>>}
   */
  massUpdate = async (getParams) => {
    return this.api.put(urls.supplies.list, getParams);
  }
  /**
   * @param {number} supply_id
   * @param {object} params
   * @returns {Promise<Array<{response: Promise<import('axios').AxiosResponse<*>>, error: (ApiError|null)}>>}
   */
  update = async (supply_id, params) => {
    return this.api.put(urls.supplies.detail.format({supply_id}), params);
  }
  /**
   * @param {number} supply_id
   * @returns {Promise<Array<{response: Promise<import('axios').AxiosResponse<*>>, error: (ApiError|null)}>>}
   */
  createAmendment = async (supply_id) => {
    return this.api.post(urls.supplies.createAmendment.format({supply_id}));
  }

  grants = async (supply_id) => {
    return this.api.get(urls.supplies.grants.format({supply_id}));
  }
  invoiceGrants = async (supply_id) => {
    return this.api.get(urls.supplies.invoiceGrants.format({supply_id}));
  }
  supplyAmendmentsGrants = async (supply_id) => {
    return this.api.get(urls.supplies.supplyAmendmentsGrants.format({supply_id}));
  }
}

class logisticSubscriptions extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.logisticSubscriptions.list, getParams);
  }
  /**
   * @param {object} data
   */
  create = async (data) => {
    return this.api.post(urls.logisticSubscriptions.list, data);
  }
  /**
   * @param {number} subscription_id
   * @param {object} data
   */
   update = async (subscription_id, data) => {
     return this.api.put(urls.logisticSubscriptions.detail.format({subscription_id}), data);
   }
   /**
   * @param {number} subscription_id
   */
   delete = async (subscription_id) => {
     return this.api.delete(urls.logisticSubscriptions.detail.format({subscription_id}));
   }
}

class BulkGrants extends ClientMixin {
  /**
   * @param {object} getParams required GET params
   */
  list = async (getParams) => {
    return this.api.get(urls.bulkGrants.list, getParams);
  }
}

class SupplyAmendments extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.supplyAmendments.list, getParams);
  }
  /**
   * @param {number} amendment_id
   */
  detail = async (amendment_id) => {
    return this.api.get(urls.supplyAmendments.detail.format({amendment_id}));
  };
  /**
   * @param {number} amendment_id
   * @param {object} data
   */
  update = async (amendment_id, data) => {
    return this.api.put(urls.supplyAmendments.detail.format({amendment_id}), data);
  };

  /**
   * @param {number} amendment_id
   * @param {object} data
   */
  signature = async (amendment_id, data) => {
    return this.api.get(urls.supplyAmendments.signatureData.format({amendment_id}), data);
  }

  /**
   * @param {number} amendment_id
   * @param {object} data
   */
  signed = async (amendment_id, data) => {
    return this.api.post(urls.supplyAmendments.signed.format({amendment_id}), data);
  }

  /**
   * @param {number} amendment_id
   * @param {object} getParams optional GET params
   */
  getProductItems = async (amendment_id, getParams = {}) => {
    return this.api.get(urls.supplyAmendments.amendmentItems.format({amendment_id}), getParams);
  };

  /**
   * @param {number} amendment_id
   * @param {object} getParams optional GET params
   */
  updateProductItems = async (amendment_id, getParams = {}) => {
    return this.api.post(urls.supplyAmendments.amendmentItems.format({amendment_id}), getParams);
  };

  grants = async (amendment_id) => {
    return this.api.get(urls.supplyAmendments.grants.format({amendment_id}));
  }
}

class Tokens extends ClientMixin {
  create = async (data) => {
    return this.api.post(urls.token.list, data);
  }
  list = async () => {
    return this.api.get(urls.token.list);
  }
  delete = async (token_id) => {
    return this.api.delete(`${urls.token.list}/${token_id}`);
  }
}

class Invoices extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.invoices.list, getParams);
  }
  /**
   * @param {number} supply_id
   */
  createInvoice = async (supply_id) => {
    return this.api.post(urls.invoices.create.format({supply_id}));
  }
  /**
   * @param {number} invoice_id
   */
  detail = async (invoice_id) => {
    return this.api.get(urls.invoices.detail.format({invoice_id}));
  };
  /**
   * @param {number} invoice_id
   * @param {object} data
   */
   update = async (invoice_id, data) => {
     return this.api.put(urls.invoices.detail.format({invoice_id}), data);
   }
   /**
  * @param {number} invoice_id
  * @param {object} getParams optional GET params
  */
  getProductItems = async (invoice_id, getParams = {}) => {
    return this.api.get(urls.invoices.invoiceItems.format({invoice_id}), getParams);
  }
  /**
  * @param {number} invoice_id
  * @param {object} data
  */
  createInvoiceItem = async (invoice_id, data) => {
    return this.api.post(urls.invoices.invoiceItems.format({invoice_id}), data);
  }

  /**
  * @param {number} invoice_id
  * @param {number} item_id
  * @param {object} data
  */
  updateInvoiceItem = async (invoice_id, item_id, data) => {
    return this.api.put(urls.invoices.invoiceItem.format({invoice_id, item_id}), data);
  }

  /**
  * @param {number} invoice_id
  * @param {number} item_id
  */
  deleteInvoiceItem = async (invoice_id, item_id) => {
    return this.api.delete(urls.invoices.invoiceItem.format({invoice_id, item_id}));
  }

  getSignData = async (invoice_id) => {
    return this.api.get(urls.invoices.invoiceSignData.format({invoice_id}));
  }

  sign = async (invoice_id, data) => {
    return this.api.post(urls.invoices.invoiceSigned.format({invoice_id}), data);
  }

  grants = async (invoice_id) => {
    return this.api.get(urls.invoices.grants.format({invoice_id}));
  }
}

class Receipts extends ClientMixin {
  /**
   * @param {object} getParams optional GET params
   */
  list = async (getParams = {}) => {
    return this.api.get(urls.receipts.list, getParams);
  }
  /**
   * @param {number} receipt_id
   */
  detail = async (receipt_id) => {
    return this.api.get(urls.receipts.detail.format({receipt_id}));
  };
  /**
   * @param {number} receipt_id
   * @param {object} data
   */
  update = async (receipt_id, data) => {
    return this.api.put(urls.receipts.detail.format({receipt_id}), data);
  };

  /**
   * @param {number} receipt_id
   */
  getSignData = async (receipt_id) => {
    return this.api.get(urls.receipts.signatureData.format({receipt_id}));
  }

  /**
   * @param {number} receipt_id
   * @param {object} data
   */
  sign = async (receipt_id, data) => {
    return this.api.post(urls.receipts.signed.format({receipt_id}), data);
  }

  grants = async (receipt_id) => {
    return this.api.get(urls.receipts.grants.format({receipt_id}));
  }

  /**
   * @param {object} data
   */
  create = async (data = {}) => {
    return this.api.post(urls.receipts.list, data);
  }
}

class Version extends ClientMixin {
  getVersion = async () => {
    return this.api.get(urls.appVersion.backend);
  }
}

class API {
  constructor() {
    this.auth = new Auth(this);
    this.organizations = new Organizations(this);
    this.users = new Users(this);
    this.organizationUnits = new OrganizationUnits(this);
    this.territorialUnits = new TerritorialUnits(this);
    this.orders = new Orders(this);
    this.nomenclature = new Nomenclature(this);
    this.userRoles = new UserRoles(this);
    this.staticData = new StaticData(this);
    this.agreements = new Agreements(this);
    this.suppliers = new Suppliers(this);
    this.tags = new Tags(this);
    this.supplies = new Supplies(this);
    this.bulkGrants = new BulkGrants(this);
    this.logisticSubscriptions = new logisticSubscriptions(this);
    this.invoices = new Invoices(this);
    this.supplyAmendments = new SupplyAmendments(this);
    this.tokens = new Tokens(this);
    this.receipts = new Receipts(this);
    this.version = new Version(this);
  }
  get client() {
    return _axios;
  }

  _request = _request;

  /**
   * @param {string} url
   * @param {object} getParams optional GET params
   * @returns {Promise.<Array.<{response: Promise<import('axios').AxiosResponse<*>>, error: ApiError | null}>>}
   */
  async get(url, getParams = {}) {
    if (!isEmpty(getParams)) {
      url += `?${qs.stringify(getParams)}`;
    }
    return this._request(this.client.get, url);
  }
  /**
   * @param {string} url
   * @param {object | FormData} data
   * @returns {Promise.<Array.<{response: Promise<import('axios').AxiosResponse<*>>, error: ApiError | null}>>}
   */
  async post(url, data = {}) {
    return this._request(this.client.post, url, data);
  }

  /**
   * @param {string} url
   * @param {object | FormData} data
   * @returns {Promise.<Array.<{response: Promise<import('axios').AxiosResponse<*>>, error: ApiError | null}>>}
   */
  async put(url, data) {
    return this._request(this.client.put, url, data);
  }

  /**
   * @param {string} url
   * @returns {Promise.<Array.<{response: Promise<import('axios').AxiosResponse<*>>, error: ApiError | null}>>}
   */
  async delete(url) {
    return this._request(this.client.delete, url);
  }
}

const api = new API();
export default api;

import {createApp} from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VClickOutside from '@/directives/VClickOutside';
import VueSweetalert2 from 'vue-sweetalert2';
import '@/utils/proto';
import '@/utils/googleAnalytics';
import 'v-calendar/style.css';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);

app.directive(VClickOutside.name, VClickOutside);


app.use(store)
  .use(router)
  .use(VueSweetalert2)
  .mount('#app');
